import { React, useState, useEffect } from "react";

import { Web3AuthNoModal } from "@web3auth/no-modal";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { ethers } from "ethers";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import UserService from "../Services/UserService";
import axios from "axios";
import ContractService from "../Services/ContractService";
import { Button, Image, Modal, ModalBody } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import { getCurrentMaticUSDPrice } from "../Services/MaticPriceService";
import MainStorage from "../store/MainStorage";

import "../scss/web3auth.scss";
import "../scss/popups.scss";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { AnimatePresence } from "framer-motion";
import ModalBox from "./Modal/ModalBox";
import LoginLiteModal from "./LoginLite/LoginLiteModal";

const Web3AuthComponent = ({
  provider,
  setProvider,
  contractInstance,
  setContractInstance,
  loginButtonRef,
  hideLogoutButton,
  logoutButtonRef,
  collapseNavbar = () => {},
  litelogoutButtonRef,
  admin,
}) => {
  const [web3auth, setWeb3auth] = useState();
  const setContractInstance_easypeasy = useStoreActions(
    (actions) => actions.setContractInstance
  );
  const setMPContractInstance = useStoreActions(
    (actions) => actions.setMPContractInstance
  );
  const setProveder_ep = useStoreActions((actions) => actions.setProvider);
  const balance = useStoreState((state) => state.balance);
  const setBalance = useStoreActions((actions) => actions.setBalance);
  const account = useStoreState((state) => state.account);
  const setAccount = useStoreActions((actions) => actions.setAccount);
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);
  const setLoginLite = useStoreActions((actions) => actions.setLoginLite);
  const setRole = useStoreActions((actions) => actions.setRole);
  const setEmail = useStoreActions((actions) => actions.setEmail);
  const logout = useStoreState((state) => state.logout);
  const setLogout = useStoreActions((action) => action.setLogout);
  const refreshBalance = useStoreState((state) => state.refreshBalance);
  const setName = useStoreActions((action) => action.setName);
  const setWeb3AuthReady = useStoreActions(
    (actions) => actions.setWeb3AuthReady
  );
  const loginLite = useStoreState((state) => state.loginLite);
  const setAccountLite = useStoreActions((actions) => actions.setAccountLite);
  const accountLite = useStoreState((state) => state.accountLite);
  const logoutLite = useStoreState((state) => state.logoutLite);
  const setLogoutLite = useStoreActions((action) => action.setLogoutLite);

  const [copied, setCopied] = useState(false);
  const [hoveredOnWalletButton, setHoveredOnWalletButton] = useState(false);
  const [initFailed, setInitFailed] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [logoutFailed, setLogoutFailed] = useState(false);
  const [loginSuccessPopup, setLoginSuccessPopup] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [loginModal, setLoginModal] = useState(false);
  const [loginAgainModal, setLoginAgainModal] = useState(false);

  const navigate = useNavigate();

  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x13882",
    rpcTarget: process.env.REACT_APP_API_RPC_URL,
  };

  useEffect(() => {
    const init = async () => {
      try {
        setInitializing(true);
        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig },
        });

        const _web3auth = new Web3AuthNoModal({
          clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
          web3AuthNetwork: "testnet", // mainnet, aqua, celeste, cyan or testnet

          // chainConfig,
          privateKeyProvider: privateKeyProvider,
          uiConfig: {
            // theme: {primary: "#000000",onPrimary: "#000000"},
            // mode:"dark",
            loginMethodsOrder: ["google", "facebook"],
            appLogo: "/favicon/64_64.png",
          },
          sessionTime: 86400 * 7, // JWT will be valid for 24x7 hours
        });

        // const privateKeyProvider = new EthereumPrivateKeyProvider({
        //   config: { chainConfig },
        // });

        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            uxMode: "redirect",
            replaceUrlOnRedirect: false,
          },
          // privateKeyProvider,
        });

        _web3auth.configureAdapter(openloginAdapter);

        setWeb3auth(_web3auth);
        console.log("web3auth set: ", _web3auth);

        await _web3auth.init();

        console.log("web3auth status:", _web3auth.status);
        const web3LoggedIn = _web3auth.status === "connected";

        if (!web3LoggedIn) {
          if (loginLite) {
            handleLoginLite();
          } else {
            handleWeb3AuthLogout(_web3auth);
          }
          setInitializing(false);
        }

        if (web3LoggedIn) {
          handleLogin(_web3auth);
        }

        const price = await getCurrentMaticUSDPrice("1000000000000000000000");
        console.log("PRICE MATIC in USD", price);
      } catch (error) {
        console.log("INITIALIZATION_ERROR:", error);
        setInitFailed(true);
        MainStorage.persist.clear().then(() => {
          console.log("Persisted state has been removed");
        });
      }
    };

    // ToDo - Try waiting for web3auth init, then call loginlite
    // if (loginLite) {
    //   handleLoginLite();
    // }

    init();
  }, []);

  useEffect(() => {
    if (provider) {
      getBalance();
      getWalletAddress();
    }
  }, [provider]);

  useEffect(() => {
    if (provider) {
      getBalance();
    }
  }, [refreshBalance]);

  useEffect(() => {
    if (logout) {
      handleWeb3AuthLogout();
      setLogout(false);
    }
  }, [logout]);

  useEffect(() => {
    if (logoutLite) {
      handleLogoutLite();
      setLogoutLite(false);
    }
  }, [logoutLite]);

  useEffect(() => {
    if (!initializing) {
      setWeb3AuthReady(true);
    }
  }, [initializing]);

  const handleLogin = async (_web3auth = web3auth) => {
    try {
      if (initFailed) {
        alert("Something went wrong. Please reload.");
        return;
      }

      await getUserInfo(_web3auth);

      const adapterName = _web3auth?.connectedAdapterName;

      let idToken = localStorage.getItem("idToken");

      if (!idToken) {
        idToken = await getIdTokenJWT(_web3auth);
      }

      if (!idToken) {
        console.log("idToken not generated. Logging out.");
        handleWeb3AuthLogout(_web3auth);
        setInitializing(false);
        setLoginAgainModal(true);
        return;
      }

      let typeOfLogin;
      if (!localStorage.getItem("appPubKey")) {
        console.log("setting appPubKey");
        const idTokenData = await getIdTokenInfoForVerification(
          adapterName,
          _web3auth
        );
        console.log("idTokenData", idTokenData);
        typeOfLogin = idTokenData.user.typeOfLogin;
        if (idTokenData.user.aggregateVerifier === "tkey-sms-passwordless-lrc")
          typeOfLogin = "phone";
        else if (
          idTokenData.user.aggregateVerifier ===
          "tkey-auth0-email-passwordless-lrc"
        )
          typeOfLogin = "email";
        else if (typeOfLogin === undefined) typeOfLogin = "external wallet";
        localStorage.setItem("appPubKey", idTokenData.app_pub_key);
        console.log("appPubKey : ", idTokenData.app_pub_key);
      }

      axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;

      axios.defaults.headers.common["appPubKey"] =
        localStorage.getItem("appPubKey");
      const res = await UserService.loginUser(typeOfLogin);
      console.log(res);
      setRole(res.user?.role);
      setName(res.user?.name);
      setLoggedIn(true);

      if (loginLite) {
        setLoginLite(false);
        localStorage.removeItem("idTokenLite");
        localStorage.removeItem("idTokenLiteTimeStamp");
        setAccountLite(null);
      }

      if (sessionStorage.getItem("loggingIn") === "true") {
        setLoginSuccessPopup(true);
        setTimeout(() => {
          setLoginSuccessPopup(false);
          sessionStorage.removeItem("loggingIn");
        }, 2000);
      }
      // throw new Error(" error for testing");

      if (!contractInstance) {
        console.log("user is logged in, but contract instance is not set");
        const _contractInstance = await ContractService._intializeContract(
          _web3auth.provider
        );

        setContractInstance_easypeasy(_contractInstance);
        setContractInstance(_contractInstance);

        setMPContractInstanceFunction();
        console.log("Contract instance set");
        console.log(_contractInstance);
      }

      setInitializing(false);
    } catch (e) {
      console.log("LOGIN_ERROR:", e);
      setLoginFailed(true);
      setInitializing(false);
      alert("Something went wrong. Please try again.");
      handleWeb3AuthLogout(_web3auth);
    }
  };

  const handleWeb3AuthLogin = async (loginData, loginType) => {
    if (initFailed || !web3auth) {
      alert("Something went wrong. Please reload.");
      return;
    }

    // --- Extra security step (This step is added just in case logout function fails to logout web3auth, but other states [isLoggedIn etc] get cleared and then user tries to login again, the button will be usable.) ---
    const status = await web3auth.status;
    if (status === "connected") {
      if (loginFailed || logoutFailed) {
        console.log("Logging out web3auth");
        await web3auth.logout();
        setLoginFailed(false);
        setLogoutFailed(false);
      }
    }

    // --- Everything good, continue with login ---
    sessionStorage.setItem("pathName", window.location.pathname);
    sessionStorage.setItem("loggingIn", true);
    console.log(window.location.pathname);

    const loginProvider = {
      email: "email_passwordless",
      phone: "sms_passwordless",
    }[loginType];

    console.log("loginProvider: ", loginProvider);
    console.log(loginData);
    // return;

    const web3authProvider = await web3auth.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        loginProvider: loginProvider,
        extraLoginOptions: {
          login_hint: loginData,
        },
      }
    );
    // await handleLogin();
  };

  const handleWeb3AuthLogout = async (_web3auth = web3auth) => {
    if (!_web3auth || initFailed) {
      console.log("web3auth not initialized yet");
      alert("Something went wrong. Please reload.");
      // return;
    }

    if (_web3auth.status === "connected") {
      try {
        await _web3auth.logout();
        setLogoutFailed(false);
        console.log("Logged out successfully");
      } catch (e) {
        console.log("Error in logging out", e);
        setLogoutFailed(true);
        MainStorage.persist.clear().then(() => {
          console.log("Persisted state has been removed");
        });
      }
    } else {
      console.log("User not logged into web3auth");
    }

    console.log("Resetting other states & localstorage");
    setLoggedIn(false);
    setLoginFailed(false);
    localStorage.removeItem("web3account");
    setBalance(null);
    setAccount(null);
    setContractInstance(null);
    setRole(null);
    setEmail(null);
    setName(null);

    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["appPubKey"];

    localStorage.removeItem("idToken");
    localStorage.removeItem("appPubKey");
    localStorage.removeItem("idTokenTimestamp");
    console.log("States & localStorage reset successful");
  };

  const getBalance = async () => {
    const userAddress = await provider.listAccounts();
    const balance = ethers.utils.formatEther(
      await provider.getBalance(userAddress[0]) // Balance is in wei
    );

    console.log("balance:", balance);
    setBalance(balance);
    return balance;
  };

  const getWalletAddress = async () => {
    const acc = await provider.listAccounts();
    console.log("account:", acc);
    localStorage.setItem("web3account", acc[0]);
    setAccount(acc[0]);
  };

  const getUserInfo = async (_web3auth = web3auth) => {
    // web3auth parameter will be provided from init function when it is just initialized
    // from loginHandler function, no need to provide, as web3auth state variable will be already set till now
    const userInfo = await _web3auth.getUserInfo();
    console.log("userInfo:", userInfo);
    setEmail(userInfo.email);

    if (_web3auth.provider) {
      let _provider = new ethers.providers.Web3Provider(_web3auth.provider);
      // setting provider for calling getBalance() & getWalletAddress() through useEffect
      setProvider(_provider);
      setProveder_ep(_provider);
    }

    return userInfo;
  };

  const getIdTokenJWT = async (web3auth) => {
    console.log("Setting idToken");
    let idToken;

    // if (adapterName === "openlogin") {
    //   const userInfo = await getUserInfo(_web3auth);
    //   idToken = userInfo.idToken;
    //   console.log("userInfo", userInfo);
    // } else {
    //   const token = await _web3auth.authenticateUser();
    //   idToken = token.idToken;
    // }

    const token = await web3auth.authenticateUser();
    console.log("token:", token);
    idToken = token.idToken;
    localStorage.setItem("idToken", idToken);
    // localStorage.setItem("idTokenTimestamp", new Date().getTime());
    return idToken;
  };

  const getIdTokenInfoForVerification = async (adapterName, web3auth) => {
    try {
      if (adapterName === "openlogin") {
        const app_scoped_privkey = await web3auth.provider?.request({
          method: "eth_private_key", // use "private_key" for other non-evm chains
        });
        const app_pub_key = getPublicCompressed(
          Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")
        ).toString("hex");

        /* Assuming user is logged in, get the userInfo containtaing idToken */
        const user = await web3auth.getUserInfo();

        return { user, app_pub_key };
      } else {
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        const provider = new ethers.providers.Web3Provider(web3auth.provider);
        // setProvider(provider);
        const walletAddress = (await provider.listAccounts())[0];
        const user = await web3auth.getUserInfo();
        return { user, app_pub_key: walletAddress };
      }
    } catch (error) {
      console.log("ERROR::getIdTokenInfoForVerification", error);
    }
  };

  const setMPContractInstanceFunction = async () => {
    try {
      const m_c_i = await ContractService.initilizeMPContract();
      setMPContractInstance(m_c_i);
      console.log("mpContractInstance:", m_c_i);
      console.log("Marketplace contract instance is set");
    } catch (e) {
      console.log("ERROR::SETTING_MARKETPLACE_CONTRACT_INSTACE::", e);
    }
  };

  const handleLoginLite = (userData, token) => {
    console.log("Lite login");

    setLoginLite(true);

    let idToken = token;

    const cachedIdTokenLite = localStorage.getItem("idTokenLite");
    const cachedTimestamp = localStorage.getItem("idTokenLiteTimeStamp");
    const currentTime = new Date().getTime();

    if (
      cachedIdTokenLite &&
      cachedTimestamp &&
      currentTime - cachedTimestamp < 3600000 * 24
    ) {
      console.log("using cached idtokenlite");
      idToken = localStorage.getItem("idTokenLite");
    } else {
      if (!token) {
        handleLogoutLite();
      } else {
        console.log("setting new idtokenlite", token);
        localStorage.setItem("idTokenLite", token);
        localStorage.setItem("idTokenLiteTimeStamp", new Date().getTime());
      }
    }

    console.log("setting header lite id tokeN:", idToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;

    if (accountLite) {
      console.log("setting account from lite:, ", accountLite);
      setAccount(accountLite);
    } else {
      console.log("setting account from lite first time:, ", userData);
      setAccountLite(userData?.walletAddress);
      setAccount(userData?.walletAddress);
    }

    // navigate("/account/reservations-and-notify");

    return true;
  };

  const handleLogoutLite = () => {
    localStorage.removeItem("idTokenLite");
    localStorage.removeItem("idTokenLiteTimeStamp");
    setAccountLite(null);
    setAccount(null);
    delete axios.defaults.headers.common["Authorization"];
    setLoginLite(false);
    navigate("/");
    console.log("logout completed");
  };

  return (
    <div className="web3auth-component">
      {!isLoggedIn ? (
        <>
          {loginLite && (
            <div className="details-container position-relative login-lite">
              <Button
                title={account}
                disabled={!account}
                className="navbar-button wallet-address-button "
                onClick={() => {
                  try {
                    navigator.clipboard.writeText(account);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 1000);
                  } catch (e) {
                    console.log("Error while copying");
                  }
                }}
                onMouseOver={() => setHoveredOnWalletButton(true)}
                onMouseOut={() => setHoveredOnWalletButton(false)}
              >
                {account ? (
                  <>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 31 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-1 align-bottom"
                    >
                      <path
                        d="M27.4993 18.2133H22.5272C20.7016 18.2122 19.2218 16.7336 19.2207 14.9079C19.2207 13.0823 20.7016 11.6037 22.5272 11.6025H27.4993"
                        stroke="#5956ff"
                        strokeWidth="2.45638"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.0898 14.8328H22.707"
                        stroke="#5956ff"
                        strokeWidth="2.45638"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.4383 4.21704H21.054C24.6135 4.21704 27.4992 7.10268 27.4992 10.6622V19.4769C27.4992 23.0364 24.6135 25.922 21.054 25.922H10.4383C6.8788 25.922 3.99316 23.0364 3.99316 19.4769V10.6622C3.99316 7.10268 6.8788 4.21704 10.4383 4.21704Z"
                        stroke="#5956ff"
                        strokeWidth="2.45638"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.56348 9.79126H16.1944"
                        stroke="#5956ff"
                        strokeWidth="2.45638"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>
                      {account.substring(0, 4) +
                        "..." +
                        account.substring(account.length - 4)}{" "}
                    </span>
                  </>
                ) : (
                  "Wallet addr"
                )}
                {account && (
                  <div className="copy-icon-container position-absolute">
                    {/* <Image src="/icon-copy.gif" height={20} className="copy-icon" /> */}
                    <span className="copy-button-icon">
                      <svg
                        viewBox="0 0 24 24"
                        className="copy"
                        style={copied ? { opacity: "0" } : {}}
                      >
                        <path
                          fill="currentColor"
                          d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                        ></path>
                      </svg>
                      <svg
                        viewBox="0 0 24 24"
                        className="copy-success"
                        style={copied ? { opacity: "1" } : {}}
                      >
                        <path
                          fill="currentColor"
                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                )}
              </Button>
            </div>
          )}
          <Button
            type="submit"
            onClick={() => {
              setLoginModal(true);

              // handleWeb3AuthLogin();
            }}
            className="navbar-button paidrez-btn me-1 login-button bg-transparent shadow-none"
            ref={loginButtonRef}
            disabled={initializing}
          >
            {loginLite ? "Full Sign In" : "Sign In"}
          </Button>

          {loginLite && (
            <Button
              className={hideLogoutButton ? "d-none" : "logout-button"}
              onClick={() => {
                handleLogoutLite();
              }}
              ref={litelogoutButtonRef}
            >
              Sign Out
            </Button>
          )}
        </>
      ) : (
        <div className="details-container position-relative">
          <Button
            title={account}
            disabled={!account}
            className="navbar-button wallet-address-button "
            onClick={() => {
              try {
                navigator.clipboard.writeText(account);
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              } catch (e) {
                console.log("Error while copying");
              }
            }}
            onMouseOver={() => setHoveredOnWalletButton(true)}
            onMouseOut={() => setHoveredOnWalletButton(false)}
          >
            {account ? (
              <>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 31 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-1 align-bottom"
                >
                  <path
                    d="M27.4993 18.2133H22.5272C20.7016 18.2122 19.2218 16.7336 19.2207 14.9079C19.2207 13.0823 20.7016 11.6037 22.5272 11.6025H27.4993"
                    stroke="#5956ff"
                    strokeWidth="2.45638"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.0898 14.8328H22.707"
                    stroke="#5956ff"
                    strokeWidth="2.45638"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.4383 4.21704H21.054C24.6135 4.21704 27.4992 7.10268 27.4992 10.6622V19.4769C27.4992 23.0364 24.6135 25.922 21.054 25.922H10.4383C6.8788 25.922 3.99316 23.0364 3.99316 19.4769V10.6622C3.99316 7.10268 6.8788 4.21704 10.4383 4.21704Z"
                    stroke="#5956ff"
                    strokeWidth="2.45638"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.56348 9.79126H16.1944"
                    stroke="#5956ff"
                    strokeWidth="2.45638"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span>
                  {account.substring(0, 4) +
                    "..." +
                    account.substring(account.length - 4)}{" "}
                </span>
              </>
            ) : (
              "Wallet addr"
            )}
            {account && (
              <div className="copy-icon-container position-absolute">
                {/* <Image src="/icon-copy.gif" height={20} className="copy-icon" /> */}
                <span className="copy-button-icon">
                  <svg
                    viewBox="0 0 24 24"
                    className="copy"
                    style={copied ? { opacity: "0" } : {}}
                  >
                    <path
                      fill="currentColor"
                      d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                    ></path>
                  </svg>
                  <svg
                    viewBox="0 0 24 24"
                    className="copy-success"
                    style={copied ? { opacity: "1" } : {}}
                  >
                    <path
                      fill="currentColor"
                      d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                    ></path>
                  </svg>
                </span>
              </div>
            )}
          </Button>

          {copied && (
            <div className="copied-popup position-absolute ms-3 p-2 ">
              <small> Copied!</small>
            </div>
          )}

          <Button
            title="Balance | Click to refresh"
            disabled={!balance}
            className="mx-1 navbar-button balance-button position-relative"
            onClick={getBalance}
          >
            {balance ? (
              <>
                <Image
                  src="/polygon-icon.png"
                  width={20}
                  className="me-1 align-bottom"
                />
                <span>{String(balance).substring(0, 5) + " MATIC"}</span>
              </>
            ) : (
              "fetching balance"
            )}

            {account && (
              <div className="refresh-balance-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="15"
                  height="15"
                  viewBox="0 0 30 30"
                >
                  <path
                    fill="currentColor"
                    d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"
                  ></path>
                </svg>
              </div>
            )}
          </Button>

          <Button
            className={
              hideLogoutButton || admin ? "d-none" : "buy-tokens-button w-100"
            }
            onClick={() => {
              navigate("/buy-tokens");
              collapseNavbar();
            }}
          >
            Buy MATIC
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              handleWeb3AuthLogout();
              setTimeout(() => navigate("/"), 1000);
            }}
            className={hideLogoutButton ? "d-none" : "logout-button"}
            ref={logoutButtonRef}
          >
            Sign Out
          </Button>
        </div>
      )}
      {/* <Button
        onClick={() => {
          const fun = async () => {
            await web3auth.authenticateUser();
          };
          fun();
        }}
      >
        Click me
      </Button> */}
      <Modal
        show={loginSuccessPopup}
        onHide={() => setLoginSuccessPopup(false)}
        dialogClassName="signup-successful-popup"
        centered
      >
        <ModalBody>
          <Image src="/loggedIn.png" alt="" height={220} />
        </ModalBody>
      </Modal>

      <Modal
        show={loginModal}
        onHide={() => setLoginModal(false)}
        dialogClassName="login-lite-modal"
        centered
      >
        <ModalBody>
          <LoginLiteModal
            closeModal={() => setLoginModal(false)}
            verified={handleLoginLite}
            collapseNavbar={collapseNavbar}
            handleWeb3AuthLogin={handleWeb3AuthLogin}
          />
        </ModalBody>
      </Modal>

      <Modal
        show={loginAgainModal}
        onHide={() => setLoginAgainModal(false)}
        centered
      >
        <ModalBody>
          <p className="fw-bold text-center">
            For security reasons, we require you to Sign In Again.
          </p>
          <Button
            className="d-block mx-auto paidrez-btn"
            onClick={() => {
              loginButtonRef.current?.click();
              setLoginAgainModal(false);
            }}
          >
            Sign In
          </Button>
        </ModalBody>
      </Modal>

      {/* <AnimatePresence initial={false} exit={true} onExitComplete={() => null}>
        {loginModal && (
          <ModalBox
            modalOpen={loginModal}
            handleClose={() => setLoginModal(false)}
            children={
              <LoginLiteModal
                closeModal={() => setLoginModal(false)}
                verified={handleLoginLite}
                collapseNavbar={collapseNavbar}
                handleWeb3AuthLogin={handleWeb3AuthLogin}
              />
            }
            style_class={"login-lite-modal"}
          />
        )}
      </AnimatePresence> */}
    </div>
  );
};

export default Web3AuthComponent;
