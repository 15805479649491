import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import ModalBox from "../Modal/ModalBox";
import {
  _useReservation,
  _useReservationLite,
  getPastReservations,
  getUpcomingReservations,
  useReservation,
  useReservationLite,
} from "../../Services/BlockchainBackendService";
import Moment from "react-moment";
import { useStoreActions, useStoreState } from "easy-peasy";
import ConfirmNFTUse from "./ConfirmNFTUse";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import TransferNFT from "./AccountPopups/TransferNFT";
import TransferConfirmation from "./AccountPopups/TransferConfirmation";
import SaleConfirmation from "./AccountPopups/SaleConfirmation";
import SaleNFT from "./AccountPopups/SaleNFT";
import ApproveNFTContract from "./AccountPopups/ApproveNFTContract";
import { useNavigate, useOutletContext } from "react-router-dom";
import MarketplaceService from "../../Services/MarketplaceService";
import moment from "moment";
import ErrorPopup from "../ErrorPopup";
import ClockIcon from "../Icons/ClockIcon";
import PeopleIcon from "../Icons/PeopleIcon";
import CalendarTimeIcon from "../Icons/CalendarTimeIcon";

const ReservationsAndNotify = () => {
  const [pastReservations, setPastReservations] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [listedReservations, setListedReservations] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [pastLoading, setPastLoading] = useState(false);
  const [upcomingLoading, setUpcomingLoading] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const [modalValue, setModalValue] = useState(0);
  const [closeModalOnOutsideClick, setCloseModalOnOutsideClick] =
    useState(true);
  const [transferToAddress, setTransferToAddress] = useState(null);
  const [transferLoading, setTransferLoading] = useState(true);
  const [listingPrice, setListingPrice] = useState("");
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const account = useStoreState((state) => state.account);
  const contractInstance = useStoreState((states) => states.contractInstance);
  const provider = useStoreState((state) => state.provider);
  const refreshBalance = useStoreState((state) => state.refreshBalance);
  const setRefreshBalance = useStoreActions(
    (action) => action.setRefreshBalance
  );
  const loginLite = useStoreState((state) => state.loginLite);

  const navigate = useNavigate();

  const { loginButtonRef } = useOutletContext();

  const closeModal = () => {
    setCloseModalOnOutsideClick(true);
    setModalOpen(false);
    setModalValue(0);
    setSelectedNFT(null);
    setQrCodeData("");
    setShowQrCode(false);
    setListingPrice("");
  };

  const fetchReservations = async () => {
    setUpcomingLoading(true);
    const res = await getUpcomingReservations({
      walletAddress: account,
    });

    console.log("upcoming reservaitons:", res.reservations);

    setUpcomingReservations(res.reservations);
    setUpcomingLoading(false);
  };

  const fetchPastReservations = async () => {
    setPastLoading(true);
    const res = await getPastReservations({
      walletAddress: account,
    });
    console.log("res past", res);
    setPastReservations(res.reservations);
    setPastLoading(false);
  };

  const fetchListedReservations = async () => {
    try {
      const res = await MarketplaceService.getListings({
        seller: account,
        startTime: moment().unix(),
        sortBy: "timestamp",
      });
      console.log(res.data.data);
      setListedReservations(res.data.data);
    } catch (e) {
      console.log("ERROR::", e);
    }
  };

  useEffect(() => {
    if (account) {
      fetchReservations();
      fetchPastReservations();
      fetchListedReservations();
    }
  }, [account]);

  useEffect(() => {
    console.log("listed res:", listedReservations);
  }, [listedReservations]);

  useEffect(() => {
    if (selectedNFT) {
      if (selectedNFT.isUtilized && modalValue === 1) {
        handleQRCode();
      }
      setModalOpen(true);
    }
  }, [selectedNFT]);

  const handleModalError = () => {
    setModalValue(5);
  };

  const handleQRCode = async () => {
    setQrCodeData("id:" + selectedNFT._id);
    setShowQrCode(true);
  };

  const handleNFTUse = async (nft) => {
    try {
      setCloseModalOnOutsideClick(false);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const res = loginLite
        ? await _useReservationLite(nft._id)
        : await _useReservation(nft._id);
      console.log(res);
      setCloseModalOnOutsideClick(true);

      if (res) {
        handleQRCode();
        fetchReservations();
      }
    } catch (e) {
      handleModalError();
      setCloseModalOnOutsideClick(true);
      console.log("ERROR:NFTUSE:", e);
    }
  };

  //toAddress should be taken as input from user
  //on Transfer clickm it should open a pop with input for toAddress and
  //it shouldshow estimated gas same as Mint transaction with confirm and reject button
  //use below functionality to do contract interaction
  const handleTransfer = async () => {
    try {
      setTransferLoading(true);
      setCloseModalOnOutsideClick(false);
      const nft = selectedNFT;
      const toAddress = transferToAddress;
      console.log("nft tokenId", nft.tokenId);
      const tokenId = nft.tokenId;
      const from = (await provider.listAccounts())[0];
      const receipt = await contractInstance.transferFrom(
        from,
        toAddress,
        tokenId
      );

      console.log(receipt);

      receipt.wait().then((res) => {
        console.log(res);
        setTransferLoading(false);
        setCloseModalOnOutsideClick(true);
        setModalValue(4);
        updateBalance();

        setTimeout(() => {
          closeModal();
          fetchReservations();
          fetchPastReservations();
        }, 3000);
      });
    } catch (e) {
      console.log("TRANSFER_ERROR::", e);
      setCloseModalOnOutsideClick(true);
      handleModalError();
    }
  };

  const openTransferConfirmationPupup = (toAddress) => {
    setTransferToAddress(toAddress);
    setModalValue(3);
  };

  const updateBalance = () => {
    setRefreshBalance(!refreshBalance);
  };

  return (
    <div className="reservations-and-notify-container">
      {!isLoggedIn && !loginLite ? (
        <div className="pt-4">
          <h4 className="paidrez-color ">
            You are not signed in. Please Sign In to view your reservations.
          </h4>
          <Button
            className="paidrez-btn"
            onClick={() => loginButtonRef.current?.click()}
          >
            Sign In
          </Button>
        </div>
      ) : (
        <>
          <div className="upcoming-reservations-container">
            <h4>Upcoming reservations</h4>

            {upcomingLoading ? (
              <div className="loader-container text-center">
                <LoadingAnimationSpoon className="loader" width={150} />
              </div>
            ) : (
              <>
                {upcomingReservations.length === 0 ? (
                  <div>You don't have any upcoming reservations.</div>
                ) : (
                  <Container>
                    <Row className="nfts-section">
                      {upcomingReservations.map((nft, i) => (
                        <Col className="nft" key={i}>
                          <Card className="nft-container1">
                            <Card.Img
                              variant="top"
                              src={nft.s3ImageURI}
                              alt="nft-image"
                              className="nft-image"
                            />
                            <div className="time-container">
                              <ClockIcon />
                              <i className="divider"></i>
                              <span className="time">
                                {
                                  <Moment format="LT">
                                    {nft.slotTime &&
                                      new Date(nft.slotTime * 1000)
                                        .toString()
                                        .split(".")[0]}
                                  </Moment>
                                }
                              </span>
                            </div>
                            <Card.Body className="p-0">
                              <Card.Title>
                                <h1 className="nft-name">
                                  {nft.tokenName ||
                                    `PAIDREZ ${
                                      nft.restoData?.name
                                    } #${nft.tokenId
                                      ?.toString()
                                      .padStart(5, "0")}`}
                                </h1>

                                <h3 className="restaurant-name">
                                  <span>at</span>{" "}
                                  {nft.restoData && nft.restoData.name}
                                </h3>

                                <p className="token-details mb-1">
                                  {nft.voucher &&
                                    `Token ID: ${nft.voucher.tokenId}`}
                                </p>
                              </Card.Title>
                              <Card.Text as="div">
                                <Row>
                                  <Col
                                    xs={6}
                                    className="nft-info-container seats"
                                  >
                                    <div className="nft-info ">
                                      <PeopleIcon />
                                      <i className="divider"></i>
                                      {nft.seatCapacity} Seats
                                    </div>
                                  </Col>{" "}
                                  <Col
                                    xs={6}
                                    className="nft-info-container date"
                                  >
                                    <div className="nft-info ">
                                      <CalendarTimeIcon />
                                      <i className="divider"></i>
                                      <Moment format="MMM Do YYYY">
                                        {new Date(nft.slotTime * 1000)}
                                      </Moment>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="use-now-button-container">
                                  <Button
                                    className="paidrez-btn"
                                    onClick={() => {
                                      setModalValue(1);
                                      setSelectedNFT(nft);
                                    }}
                                    // disabled={nft.isUtilized}
                                  >
                                    {nft.isUtilized ? (
                                      <>
                                        Used{" "}
                                        <small className="verify">
                                          (Click to Verify)
                                        </small>
                                      </>
                                    ) : (
                                      "Use Now"
                                    )}
                                  </Button>
                                </Row>

                                {!loginLite && (
                                  <Row className="transfer-sell-buttons-container gap-1 mt-1">
                                    {!nft.isListedOnMP && (
                                      <Col className="pe-0">
                                        <Button
                                          className="paidrez-btn-secondary w-100"
                                          onClick={() => {
                                            setModalValue(2);
                                            setSelectedNFT(nft);
                                          }}
                                          disabled={nft.isUtilized}
                                        >
                                          Transfer
                                        </Button>
                                      </Col>
                                    )}

                                    <Col
                                      className={nft.isListedOnMP ? "" : "ps-0"}
                                    >
                                      {!nft.isListedOnMP ? (
                                        <Button
                                          className="paidrez-btn-secondary w-100"
                                          onClick={() => {
                                            setModalValue(6);
                                            setSelectedNFT(nft);
                                          }}
                                          disabled={nft.isUtilized}
                                        >
                                          List for Sale
                                        </Button>
                                      ) : (
                                        <Button
                                          className="paidrez-btn-secondary w-100"
                                          onClick={() =>
                                            navigate(
                                              "/marketplace/nft/" + nft.tokenId
                                            )
                                          }
                                        >
                                          View Listing
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                )}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                )}
              </>
            )}
          </div>

          {listedReservations.length > 0 && (
            <div className="listed-reservations-container">
              <h4>Listed Reservations</h4>
              <Container>
                <Row className="nfts-section">
                  {listedReservations.map((nft, i) => (
                    <Col className="nft" key={i}>
                      <Card
                        className="nft-container1"
                        onClick={() => {
                          navigate("/marketplace/nft/" + nft.tokenId);
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src={nft.s3ImageURI}
                          alt="nft-image"
                          className="nft-image"
                        />
                        <div className="time-container">
                          <ClockIcon />
                          <i className="divider"></i>
                          <span className="time">
                            {
                              <Moment format="LT">
                                {nft.slotTime &&
                                  new Date(nft.slotTime * 1000)
                                    .toString()
                                    .split(".")[0]}
                              </Moment>
                            }
                          </span>
                        </div>
                        <Card.Body className="p-0">
                          <Card.Title>
                            <h1 className="nft-name">
                              {nft.tokenName ||
                                `PAIDREZ ${nft.restoName} #${nft.tokenId
                                  ?.toString()
                                  .padStart(5, "0")}`}
                            </h1>

                            <h3 className="restaurant-name">
                              <span>at</span> {nft.restoName}
                            </h3>

                            <p className="token-details mb-1">
                              {nft.voucher &&
                                `Token ID: ${nft.voucher.tokenId}`}
                            </p>
                          </Card.Title>
                          <Card.Text as="div">
                            <Row>
                              <Col xs={6} className="nft-info-container seats">
                                <div className="nft-info ">
                                  <PeopleIcon />
                                  <i className="divider"></i>
                                  {nft.seatCapacity} Seats
                                </div>
                              </Col>{" "}
                              <Col xs={6} className="nft-info-container date">
                                <div className="nft-info ">
                                  <CalendarTimeIcon />
                                  <i className="divider"></i>
                                  <Moment format="MMM Do YYYY">
                                    {new Date(nft.slotTime * 1000)}
                                  </Moment>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                xs={12}
                                className="nft-info-container mint-fee"
                              >
                                <div className="nft-info ">
                                  <span className="description mint-fee-text">
                                    {" "}
                                    Price:{" "}
                                  </span>
                                  <div className="price-container">
                                    <span className="value">
                                      {nft.priceInMatic} MATIC
                                    </span>
                                    <span className="description">
                                      {" "}
                                      ≈ ${nft.priceInMatic.toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row className="use-now-button-container">
                              <Button className="paidrez-btn">
                                View on Marketplace
                              </Button>
                            </Row>

                            {/* <Row className="transfer-sell-buttons-container gap-1 mt-1">
                              <Col className="pe-0">
                                <Button
                                  className="paidrez-btn-secondary w-100"
                                  onClick={() => {
                                    setModalValue(2);
                                    setSelectedNFT(nft);
                                  }}
                                  disabled={nft.isUtilized}
                                >
                                  Transfer
                                </Button>
                              </Col>
                              <Col className="ps-0">
                                {!nft.isListedOnMP ? (
                                  <Button
                                    className="paidrez-btn-secondary w-100"
                                    onClick={() => {
                                      setModalValue(6);
                                      setSelectedNFT(nft);
                                    }}
                                    disabled={nft.isUtilized}
                                  >
                                    List for Sale
                                  </Button>
                                ) : (
                                  <Button
                                    className="paidrez-btn w-100"
                                    onClick={() =>
                                      navigate(
                                        "/marketplace/nft/" + nft.tokenId
                                      )
                                    }
                                  >
                                    View Listing
                                  </Button>
                                )}
                              </Col>
                            </Row> */}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          )}

          {/* <div className="notify">
        <h2>Notify</h2>
        <p>
          You currently don’t have Notify set. If the reservation or ticket you
          want isn’t available, set a Notify and you will receive a notification
          when something opens up.
        </p>
      </div> */}

          <div className="past-reservations">
            <h4>Past reservations</h4>

            {pastLoading ? (
              <div className="text-center">
                <LoadingAnimationSpoon width={150} />
              </div>
            ) : (
              <>
                {pastReservations.length === 0 ? (
                  <div>You don't have any past reservations.</div>
                ) : (
                  <Container>
                    <Row className="nfts-section">
                      {pastReservations.map((nft, i) => (
                        <Col className="nft" key={i}>
                          <Card className="nft-container1">
                            <Card.Img
                              variant="top"
                              src={nft.s3ImageURI}
                              alt="nft-image"
                              className="nft-image"
                            />
                            <div className="time-container">
                              <ClockIcon />
                              <i className="divider"></i>
                              <span className="time">
                                {
                                  <Moment format="LT">
                                    {nft.slotTime &&
                                      new Date(nft.slotTime * 1000)
                                        .toString()
                                        .split(".")[0]}
                                  </Moment>
                                }
                              </span>
                            </div>
                            <Card.Body className="p-0">
                              <Card.Title>
                                <h1 className="nft-name">
                                  {nft.tokenName ||
                                    `PAIDREZ ${
                                      nft.restoData?.name
                                    } #${nft.tokenId
                                      ?.toString()
                                      .padStart(5, "0")}`}
                                </h1>

                                <div className="restaurant-name">
                                  at {nft.restoData && nft.restoData.name}
                                </div>

                                <p className="token-details mb-1">
                                  {nft.voucher &&
                                    `Token ID: ${nft.voucher.tokenId}`}
                                </p>
                              </Card.Title>
                              <Card.Text as="div">
                                <Row>
                                  <Col
                                    xs={6}
                                    className="nft-info-container seats"
                                  >
                                    <div className="nft-info ">
                                      <PeopleIcon />
                                      <i className="divider"></i>
                                      {nft.seatCapacity} Seats
                                    </div>
                                  </Col>{" "}
                                  <Col
                                    xs={6}
                                    className="nft-info-container date"
                                  >
                                    <div className="nft-info ">
                                      <CalendarTimeIcon />
                                      <i className="divider"></i>
                                      <Moment format="MMM Do YYYY">
                                        {new Date(nft.slotTime * 1000)}
                                      </Moment>
                                    </div>
                                  </Col>
                                </Row>
                                {!loginLite && (
                                  <Row className="use-now-button-container">
                                    <Button
                                      className="paidrez-btn-secondary"
                                      onClick={() => {
                                        setModalValue(2);
                                        setSelectedNFT(nft);
                                      }}
                                    >
                                      Transfer
                                    </Button>
                                  </Row>
                                )}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                )}
              </>
            )}
          </div>

          <AnimatePresence
            initial={false}
            exit={true}
            onExitComplete={() => null}
          >
            {modalOpen && (
              <ModalBox
                modalOpen={closeModalOnOutsideClick ? modalOpen : () => {}}
                handleClose={closeModal}
                children={
                  {
                    1: (
                      <ConfirmNFTUse
                        closeModal={closeModal}
                        nft={selectedNFT}
                        handleNFTUse={handleNFTUse}
                        qrCodeData={qrCodeData}
                        showQrCode={showQrCode}
                      />
                    ),
                    2: (
                      <TransferNFT
                        closeModal={closeModal}
                        openTransferConfirmationPupup={
                          openTransferConfirmationPupup
                        }
                        nftImage={selectedNFT?.s3ImageURI}
                        nftName={
                          selectedNFT?.tokenName ||
                          `PAIDREZ ${
                            selectedNFT?.restoData?.name
                          } #${selectedNFT?.tokenId
                            ?.toString()
                            .padStart(5, "0")}`
                        }
                      />
                    ),
                    3: (
                      <TransferConfirmation
                        tokenId={selectedNFT?.tokenId}
                        closeModal={closeModal}
                        to={transferToAddress}
                        handleTransfer={handleTransfer}
                        loading={transferLoading}
                        setLoading={setTransferLoading}
                        handleModalError={handleModalError}
                        updateBalance={updateBalance}
                      />
                    ),
                    4: <h4>Transferred Successfully</h4>,
                    5: <ErrorPopup closeModal={closeModal} />,
                    6: (
                      <ApproveNFTContract
                        nft={selectedNFT}
                        closeModal={closeModal}
                        handleModalError={handleModalError}
                        setModalValue={setModalValue}
                        updateBalance={updateBalance}
                      />
                    ),
                    7: (
                      <SaleNFT
                        closeModal={closeModal}
                        nft={selectedNFT}
                        setModalValue={setModalValue}
                        listingPrice={listingPrice}
                        setListingPrice={setListingPrice}
                      />
                    ),
                    8: (
                      <SaleConfirmation
                        closeModal={closeModal}
                        nft={selectedNFT}
                        listingPrice={listingPrice}
                        handleModalError={handleModalError}
                        setModalValue={setModalValue}
                        fetchReservations={fetchReservations}
                        updateBalance={updateBalance}
                      />
                    ),
                  }[modalValue]
                }
                style_class={
                  {
                    1: "confirm-use-now-modal",
                    2: "transfer-nft-modal",
                    3: "transfer-confirmation-modal",
                    4: "transfer-confirmation-modal",
                    5: "transfer-confirmation-modal",
                    6: "approve-nft-contract-modal",
                    7: "sale-nft-modal",
                    8: "sale-confirmation-modal",
                  }[modalValue]
                }
              />
            )}
          </AnimatePresence>
        </>
      )}
    </div>
  );
};

export default ReservationsAndNotify;
