import { BigNumber, ethers } from "ethers";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import { getCurrentMaticUSDPrice } from "../Services/MaticPriceService";
import LoadingAnimationSpoon from "./Helpers/LoadingAnimationSpoon";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { markNFTAsMinted } from "../Services/BlockchainBackendService";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import MarketplaceService from "../Services/MarketplaceService";

const ConfirmMintPopup = ({
  nft,
  mintReservationNFT,
  closeModal,
  alert,
  getReservationSlotNFTs,
  // --- Marketplace props below ---
  marketPlace,
  handleModalError,
  setModalValue,
  handleBuySuccess,
  setBuying,
  fromNFTPage,
  setMoveToMyReservation = () => {},
}) => {
  const [gasFee, setGasFee] = useState();
  const [loading, setLoading] = useState(true);
  const [totalFee, setTotalFee] = useState();
  const [priceInUSD, setPriceInUSD] = useState();
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [copied, setCopied] = useState(false);
  const [estimationError, setEstimationError] = useState(false);
  const [gasPriceInfo, setGasPriceInfo] = useState({});
  const [alreadyMinted, setAlreadyMinted] = useState(false);
  const [minimumBalanceRequired, setMinimumBalanceRequired] = useState(0);
  const [showMoonpay, setShowMoonpay] = useState(false);

  const contractInstance = useStoreState((state) => state.contractInstance);
  const mpContractInstance = useStoreState(
    (states) => states.mpContractInstance
  );
  const balance = useStoreState((states) => states.balance);
  const refreshBalance = useStoreState((state) => state.refreshBalance);
  const setRefreshBalance = useStoreActions(
    (action) => action.setRefreshBalance
  );
  const account = useStoreState((states) => states.account);

  const refreshBalanceButtonRef = useRef();

  useEffect(() => {
    async function init() {
      setInsufficientBalance(false);

      let gasPriceInfo = await fetchGasPrice();

      if (gasPriceInfo?.fast?.maxFee) {
        console.log("maxFee exists");
      } else {
        console.log("couldn't fetch gas price. Using default values");
        gasPriceInfo = { fast: { maxFee: 120 } };
      }

      setGasPriceInfo(gasPriceInfo);

      // if (gasPriceInfo === -1) {
      //   marketPlace
      //     ? handleModalError()
      //     : alert(
      //         "Can't get gas prices at the moment. Please try again later."
      //       );
      //   closeModal();
      //   return;
      // }

      const gasPrice = ethers.utils.parseUnits(
        gasPriceInfo.fast.maxFee.toFixed(6),
        "gwei"
      );

      const gasFeeForCheck = ethers.utils
        .formatUnits(ethers.BigNumber.from("500000").mul(gasPrice), "ether")
        .substring(0, 10);

      console.log("gasFeeForCheck", gasFeeForCheck);

      const minBal = Number(nft.priceInMatic) + Number(gasFeeForCheck);
      setMinimumBalanceRequired(minBal);
      console.log("minimum balance required:", minBal);

      if (balance < minBal) {
        setInsufficientBalance(true);
        setLoading(false);
        console.log("Insufficient balance");
      } else {
        estimateGasFees(gasPriceInfo);
      }
    }

    init();
  }, [balance]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (!showMoonpay) {
  //       // Disable the default warning
  //       event.returnValue = undefined;
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const fetchGasPrice = async () => {
    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    let gasPriceInfo;
    for (let i = 0; i < 3; i++) {
      gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("fetching gas price failed, Attempt", i + 1, e);
        });

      if (gasPriceInfo) {
        break;
      }
    }

    console.log("gas price info:", gasPriceInfo);
    return gasPriceInfo;
  };

  const estimateGasFees = async (_gasPriceInfo = gasPriceInfo) => {
    try {
      setLoading(true);
      setEstimationError(false);

      const methodName = "mint";
      const methodArgs = [nft.voucher];

      const options = {
        gasPrice: ethers.utils.parseUnits(
          _gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        value: nft.price,
      };

      // throw new Error("hehe");

      // Estimate the gas required for the transaction
      let gasEstimate;

      if (marketPlace) {
        gasEstimate = await mpContractInstance.estimateGas.buyNFTItem(
          process.env.REACT_APP_CONTRACT_ADDRESS,
          nft.tokenId,
          { gasLimit: 300000, value: nft.price }
        );
      } else {
        gasEstimate = await contractInstance.estimateGas[methodName](
          ...methodArgs, // equivalent to nft.voucher (not array, just the value)
          options
        );
      }

      console.log("Gas estimate:", gasEstimate.toString());

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 8);

      gasFee = Number(gasFee).toFixed(5);

      setGasFee(gasFee);

      console.log("Gas fee:", gasFee);

      const _totalFee = Number(nft.priceInMatic) + Number(gasFee);
      setTotalFee(_totalFee);

      const _priceInUSD = await getCurrentMaticUSDPrice(gasFee);
      setPriceInUSD(_priceInUSD);

      setLoading(false);
    } catch (error) {
      console.log("Error estimating gas:", error);
      setLoading(false);
      // console.log(error.message);
      if (
        error.message?.includes(
          `reason="execution reverted: ERC721: token already minted"`
        )
      ) {
        console.log("already minted");
        setAlreadyMinted(true);
        markAsMinted();
      }
      setEstimationError(true);
    }
  };

  const markAsMinted = async () => {
    try {
      await markNFTAsMinted(nft._id);
      getReservationSlotNFTs();
    } catch (e) {
      console.log("ERROR::", e);
    }
  };

  const handleBuy = async () => {
    // handleBuySuccess(nft);
    // return;

    // setBuying(true);
    // setTimeout(() => {
    //   setBuying(false);
    //   setModalValue(5);
    // }, 5000);

    try {
      closeModal();
      setBuying(true);

      const receipt = await mpContractInstance.buyNFTItem(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        { gasLimit: 300000, value: nft.price }
      );

      await receipt.wait();

      console.log("Buy successful");

      handleRefresh();

      // API call when transaction completes
      const res = await MarketplaceService.buyListing(nft._id);
      console.log(res);
      // fetchListing();
      setMoveToMyReservation(true);
      setBuying(false);
      if (fromNFTPage) {
        setModalValue(5);
      } else {
        handleBuySuccess(nft);
      }
    } catch (e) {
      console.log("ERROR::", e);
      setBuying(false);
      handleModalError("Transaction Failed.");
    }
  };

  const handleRefresh = () => {
    setRefreshBalance(!refreshBalance);
    refreshBalanceButtonRef.current?.classList?.add("rotate");
    setTimeout(() => {
      refreshBalanceButtonRef.current?.classList?.remove("rotate");
    }, 2000);
  };

  return (
    <div className="w-100 fee-details-container">
      <div className="close-button-container" onClick={() => closeModal()}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>
      <h3>Confirm purchase</h3>
      <hr />
      <div>
        {loading ? (
          <div className="text-center">
            <LoadingAnimationSpoon width={150} />
          </div>
        ) : (
          <>
            <Row className="nft-info">
              <p>
                <b>Token ID #{nft.tokenId}</b>
              </p>
              <Col xs={4}>
                <Image src={nft.s3ImageURI} className="w-100 nft-image" />
              </Col>

              <Col xs={8}>
                <Table borderless className="nft-info-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="p-0 mb-0 description">Restaurant</p>
                      </td>
                      <td className="value">
                        {marketPlace ? nft.restoName : nft.restaurantName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="p-0 mb-0 description">Date</p>
                      </td>
                      <td className="value">
                        {moment(nft.slotTime * 1000).format("MMM Do YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="p-0 mb-0 description">Time</p>
                      </td>
                      <td className="value">
                        {moment(nft.slotTime * 1000).format("LT")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="p-0 mb-0 description">Guests</p>
                      </td>
                      <td className="value">{nft.seatCapacity}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <hr />

            <Table borderless>
              <tbody>
                <tr>
                  <td>
                    <b className="balance description">Available Balance</b>
                  </td>
                  <td className="text-end value balance">
                    {Number(balance).toFixed(5)} MATIC
                  </td>
                </tr>
              </tbody>
            </Table>

            {insufficientBalance && (
              <div className="insufficient-balance">
                <Table borderless>
                  <tbody>
                    <tr>
                      <td>
                        <b className="balance description">
                          {marketPlace ? "Price" : "Mint Fee"}
                        </b>
                      </td>
                      <td className="text-end value balance">
                        {nft.priceInMatic} MATIC
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="wallet-address-container">
                  <p>{account}</p>
                  <button
                    type="button"
                    aria-label="Copy code to clipboard"
                    title="Copy"
                    className="clean-btn"
                    onClick={() => {
                      navigator.clipboard.writeText(account);
                      setCopied(true);
                      setTimeout(() => setCopied(false), 2000);
                    }}
                    style={
                      copied ? { opacity: "1", borderColor: "#00d600" } : {}
                    }
                  >
                    <span className="copy-button-icon" aria-hidden="true">
                      <svg
                        viewBox="0 0 24 24"
                        className="copy"
                        style={copied ? { opacity: "0" } : {}}
                      >
                        <path
                          fill="currentColor"
                          d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                        ></path>
                      </svg>
                      <svg
                        viewBox="0 0 24 24"
                        className="copy-success"
                        style={copied ? { opacity: "1" } : {}}
                      >
                        <path
                          fill="currentColor"
                          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
                <p className="text-danger fw-bold">
                  You don't have enough MATIC to buy this reservation.
                </p>

                <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
                  <Button
                    className="paidrez-btn fw-bold"
                    onClick={() => setShowMoonpay(true)}
                    // size="sm"
                  >
                    Buy MATIC
                  </Button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 30 30"
                    onClick={handleRefresh}
                    className="cursor-pointer refresh-balance-icon"
                    fill="white"
                    ref={refreshBalanceButtonRef}
                    // className="align-text-bottom"
                  >
                    <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
                  </svg>
                </div>
              </div>
            )}

            {!insufficientBalance && (
              <>
                <hr />

                <Table className="w-100 fee-info" borderless>
                  <tbody>
                    <tr>
                      <td className="description">
                        <b className="p-0">
                          {marketPlace ? "Price" : "Mint Fee"}
                        </b>
                      </td>
                      <td className="text-end value">
                        {nft.priceInMatic} MATIC
                      </td>
                    </tr>
                    <tr>
                      <td className="description">
                        {" "}
                        <b className="p-0">Gas Fee</b>
                        <small>(Estimated)</small>
                      </td>
                      <td className="text-end value">{gasFee} MATIC</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}

            {estimationError && (
              <div className="estimation-error-container">
                {alreadyMinted ? (
                  <b className="text-danger">
                    Looks like this reservations is Sold Out. Please mint
                    another one.
                  </b>
                ) : (
                  <>
                    <b className="text-danger">
                      We couldn't estimate the gas required for this
                      transaction.
                      <span
                        role="button"
                        size="sm"
                        className="try-again-button"
                        onClick={() => {
                          console.log("try again");
                          estimateGasFees();
                        }}
                      >
                        Try Again
                      </span>
                    </b>
                  </>
                )}
                <br />
                <br />
                <small>
                  If you continue to face trouble,{" "}
                  <span
                    role="button"
                    className="contact-us"
                    onClick={() =>
                      (window.location.href =
                        "mailto:support@developingnow.com?subject=PAIDREZ%20Support%20-%20Trouble%20Minting")
                    }
                  >
                    {" "}
                    Contact Us
                  </span>
                </small>
              </div>
            )}

            {!insufficientBalance && !estimationError && (
              <>
                <hr />
                <Table borderless>
                  <tbody>
                    <tr>
                      <td className="description">
                        <b className="p-0">Total</b>
                        <small>
                          ({marketPlace ? "Price" : "Mint Fee"} + Gas Fee)
                        </small>
                      </td>
                      <td className="text-end value">
                        {totalFee?.toFixed(5)} MATIC
                        <p className="p-0 m-0">
                          <small>≈ {priceInUSD?.toFixed(5)} USD</small>
                        </p>
                      </td>
                    </tr>
                  </tbody>

                  {/* <tr>
                  <td></td>
                  <td className="value">
                    <b> </b>
                  </td>
                </tr> */}
                </Table>
              </>
            )}

            {!insufficientBalance && !estimationError && (
              <div className="buttons-container">
                <Row>
                  <Col>
                    <Button onClick={closeModal} className="reject">
                      Reject
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      onClick={() => {
                        closeModal();
                        marketPlace ? handleBuy() : mintReservationNFT(nft);
                      }}
                      className="confirm"
                      disabled={insufficientBalance || estimationError}
                    >
                      {" "}
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </div>

      {showMoonpay && (
        <MoonPayBuyWidget
          variant="overlay"
          baseCurrencyCode="usd"
          baseCurrencyAmount="100"
          // currencyCode="matic"
          defaultCurrencyCode="matic"
          // visible
          showOnlyCurrencies="matic"
          quoteCurrencyAmount={Math.max(50, minimumBalanceRequired)}
          walletAddress={account}
          colorCode="#5956ff"
          onCloseOverlay={() => setShowMoonpay(false)}
        />
      )}
    </div>
  );
};

export default ConfirmMintPopup;
