import { useStoreState } from "easy-peasy";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { getCurrentMaticUSDPrice } from "../../../Services/MaticPriceService";
import { Button, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import LoadingAnimationSpoon from "../../Helpers/LoadingAnimationSpoon";
import CloseIcon from "../../Icons/CloseIcon";

const ApproveNFTContract = ({
  handleModalError,
  nft,
  setModalValue,
  closeModal,
  updateBalance,
}) => {
  const [loading, setLoading] = useState(false);
  const [approving, setApproving] = useState(false);
  const [gasPrice, setGasPrice] = useState();
  const [gasFee, setGasFee] = useState(0);
  const [maticUsdPrice, setMaticUsdPrice] = useState(0);
  const contractInstance = useStoreState((state) => state.contractInstance);

  useEffect(() => {
    getMaticUSDPrice();
    estimateGasFee();
  }, []);

  const getMaticUSDPrice = async () => {
    const usdPrice = await getCurrentMaticUSDPrice(1);
    setMaticUsdPrice(usdPrice);
  };

  const fetchGasPrice = async () => {
    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    let gasPriceInfo;
    for (let i = 0; i < 3; i++) {
      gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("fetching gas price failed, Attempt", i + 1, e);
        });

      if (gasPriceInfo) {
        break;
      }
    }

    console.log("gas price info:", gasPriceInfo);
    return gasPriceInfo;
  };

  const estimateGasFee = async () => {
    try {
      setLoading(true);

      let gasPriceInfo = await fetchGasPrice();

      if (gasPriceInfo?.fast?.maxFee) {
        console.log("maxFee exists");
      } else {
        console.log("couldn't fetch gas price. Using default values");
        gasPriceInfo = { fast: { maxFee: 120 } };
      }

      const options = {
        gasPrice: ethers.utils.parseUnits(
          gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        // value: listingPrice,
      };

      // Estimate the gas required for the transaction
      const gasEstimate = await contractInstance.estimateGas.approve(
        process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS,
        nft.tokenId
        // { gasLimit: 300000 }
      );

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 8);

      gasFee = Number(gasFee).toFixed(4);

      console.log("gas fee:", gasFee);

      setGasFee(gasFee);
      setGasPrice(gasEstimate.toString());

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);

      setLoading(false);
    } catch (e) {
      console.log("ERROR::", e);
      handleModalError();
    }
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      setApproving(true);
      const receipt = await contractInstance.approve(
        process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS,
        nft.tokenId
      );

      const res = await receipt.wait();

      console.log("APPROVED:", res);
      updateBalance();
      setModalValue(7);
    } catch (e) {
      setApproving(false);
      setLoading(false);
      console.log("ERROR::APPROVING_NFT_MARKETPLACE_CONTRACT", e);
    }
  };

  return (
    <div className="approve-nft-contract">
      <CloseIcon closeModal={closeModal} />
      <h4 className="me-4">Approve Marketplace Contract to list your NFT?</h4>
      <hr />
      <h4 className="text-muted">Estimated Gas Fee</h4>
      <div className="fee-container d-flex justify-content-between mb-5">
        {gasFee && maticUsdPrice ? (
          <>
            <p>{gasFee} MATIC</p>
            <p>~ $ {(gasFee * maticUsdPrice).toFixed(4)} USD</p>
          </>
        ) : (
          <Skeleton containerClassName="w-100" className="h-100" />
        )}
      </div>
      <hr />
      <Row className="buttons-container">
        <Col>
          <Button
            className="paidrez-btn w-100"
            onClick={handleApprove}
            disabled={loading}
          >
            Approve
          </Button>
        </Col>
        <Col>
          <Button
            className="paidrez-btn-secondary w-100"
            onClick={closeModal}
            disabled={loading}
          >
            Reject
          </Button>
        </Col>
      </Row>

      {approving && (
        <div className="loader-container">
          <LoadingAnimationSpoon width={150} />
        </div>
      )}
    </div>
  );
};

export default ApproveNFTContract;
