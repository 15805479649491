import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Image,
  NavDropdown,
  Row,
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ModalBox from "./Modal/ModalBox";
import Login from "./Login";
import Search from "./Search";
import ReactDatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import profileIcon from "./NavigationBarHelper/profile-icon.png";
import Moment from "react-moment";
import { useRef } from "react";
import Web3AuthComponent from "./Web3AuthComponent";
import { useStoreActions, useStoreState } from "easy-peasy";
import RestaurantsService from "../Services/RestaurantsService";
import ValuePropsApplyNowPopup from "./ValuePropsApplyNowPopup";
import LoginLite from "./LoginLite/LoginLite";
import paidrezLogo from "../assets/paidrez-logo.png";

// For adding close button on login modal
const LoginComponent = ({ closeModal, loginComplete }) => {
  return (
    <div className="login-component">
      <button className="close-btn" onClick={() => closeModal()}>
        <svg height="20" viewBox="0 0 20 20" width="20">
          <path
            d="m3.94621165 2.89705176.08411844.07261815 5.96966991 5.96933009 5.9696699-5.96933009c.2928932-.29289321.767767-.29289321 1.0606602 0 .2662665.26626657.2904726.68293025.0726181.97654174l-.0726181.08411844-5.9693301 5.96966991 5.9693301 5.9696699c.2928932.2928932.2928932.767767 0 1.0606602-.2662666.2662665-.6829303.2904726-.9765418.0726181l-.0841184-.0726181-5.9696699-5.9693301-5.96966991 5.9693301c-.29289322.2928932-.76776696.2928932-1.06066018 0-.26626656-.2662666-.29047261-.6829303-.07261815-.9765418l.07261815-.0841184 5.96933009-5.9696699-5.96933009-5.96966991c-.29289321-.29289322-.29289321-.76776696 0-1.06066018.26626657-.26626656.68293025-.29047261.97654174-.07261815z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      <Login loginComplete={loginComplete} onlyLogIn={true} />
    </div>
  );
};

// City selector
const cityNames = {
  1: "New York",
  2: "Miami",
  3: "San Francisco",
  4: "Atlanta",
  5: "Boston",
  6: "Austin",
  7: "Dallas",
  8: "Seattle",
  9: "Los Angeles",
  // 10: "Philadelphia",
  // 11: "Austin",
  // 12: "Phoenix",
  // 13: "Detroit",
  // 14: "",
};

const CitySelector = () => {
  const cityId = useStoreState((state) => state.cityId);
  const setCityId = useStoreActions((action) => action.setCityId);
  const [city, setCity] = useState("New York");
  const [cities, setCities] = useState([]);
  const [cityNames, setCityNames] = useState({});

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    // console.log("Cityid changed", cityId);
    setCityId(cityId);
  }, [cityId]);

  const fetchCities = async () => {
    try {
      const res = await RestaurantsService.getCities();
      setCities(res.data);
      let city_names = {};
      res.data?.forEach((c) => (city_names[c.cityId] = c.city));
      setCityNames(city_names);
    } catch (e) {
      console.log("ERROR::", e);
    }
  };

  const handleCitySelect = (e) => {
    console.log("city id being set", e);
    setCity(cityNames[e]);
    setCityId(Number(e));
    navigate("/");
  };

  const navigate = useNavigate();

  return (
    <DropdownButton
      title={city}
      id="basic-nav-dropdown"
      defaultValue={cityId}
      onSelect={handleCitySelect}
      className="d-flex justify-content-center align-items-center city-dropdown me-2"
    >
      {cities.map((city, i) => (
        <Dropdown.Item eventKey={`${city.cityId}`} key={i}>
          {city.city}
        </Dropdown.Item>
      ))}
      {/* <div>
        <Dropdown.Item eventKey="1">New York</Dropdown.Item>
        <Dropdown.Item eventKey="2">Miami</Dropdown.Item>
        <Dropdown.Item eventKey="3">San Francisco</Dropdown.Item> */}

      {/* <Dropdown.Divider /> */}
      {/* </div>
      <div>
        <Dropdown.Item eventKey="4">Atlanta</Dropdown.Item>
        <Dropdown.Item eventKey="5">Boston</Dropdown.Item>
        <Dropdown.Item eventKey="6">Austin</Dropdown.Item>
      </div>
      <div>
        <Dropdown.Item eventKey="7">Chicago</Dropdown.Item>
        <Dropdown.Item eventKey="8">Seattle</Dropdown.Item>
        <Dropdown.Item eventKey="9">Los Angeles</Dropdown.Item> */}
      {/* <Dropdown.Item eventKey="10">Philadelphia</Dropdown.Item> */}
      {/* <Dropdown.Item eventKey="11">Austin</Dropdown.Item>
        <Dropdown.Item eventKey="12">Phoenix</Dropdown.Item> */}
      {/* <Dropdown.Item eventKey="13">Detroit</Dropdown.Item>
        <Dropdown.Item eventKey="14"></Dropdown.Item> */}
      {/* </div> */}
    </DropdownButton>
  );
};

// -------------- MAIN COMPONENT ---------------
function NavigationBar({
  setCityId,
  startDate,
  setStartDate,
  numberOfGuests,
  setNumberOfGuests,
  selectedAccountPage,
  setSelectedAccountPage,
  contractInstance,
  setContractInstance,
  provider,
  setProvider,
  loginButtonRef,
  mpFilters,
  noDate,
  setNoDate,
}) {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);
  const loginLite = useStoreState((state) => state.loginLite);

  const [loginData, setLoginData] = useState("");
  const [loginType, setLoginType] = useState("");

  const location = useLocation();
  const logoutButtonRef = useRef();
  const litelogoutButtonRef = useRef();
  const navbarRef = useRef();

  const navigate = useNavigate();

  //////////// Mobile checker code ///////////////
  const [isMobile, setMobile] = useState(window.innerWidth < "992");

  const updateScreenSize = () => {
    setMobile(window.innerWidth < "992");
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    // console.log(startDate);
    return () => window.removeEventListener("resize", updateScreenSize);
  });
  ///////////// Mobile checker code //////////////

  // MODAL //
  const [modalOpen, setModalOpen] = useState(false);
  const [searchPage, setSearchPage] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeSearchPage = () => {
    setSearchPage(false);
  };

  const loginComplete = () => {
    setLoggedIn(true);
    setModalOpen(false);
  };
  // MODAL //

  // DATE //
  // const [startDate, setStartDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const DateButton = forwardRef(({ value, onClick }, ref) => (
    <Button
      className="datepicker-button"
      onClick={() => {
        onClick();
        setIsCalendarOpen(!isCalendarOpen);
      }}
      ref={ref}
    >
      {location.pathname === "/marketplace" && noDate ? (
        "Any Date"
      ) : new Date(value).toDateString() === new Date().toDateString() ? (
        "Today"
      ) : (
        <Moment format={isMobile ? "MMM Do" : "ddd, MMM Do"}>{value}</Moment>
      )}
    </Button>
  ));

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  // DATE //

  // const [numberOfGuests, setNumberOfGuests] = useState(2);
  const handlePersonNumberSelect = (e) => {
    setNumberOfGuests(Number(e));
  };

  const handleWeb3AuthLogin = (loginData, loginType) => {
    setLoginData(loginData);
    setLoginType(loginType);
  };

  if (isMobile) {
    return (
      <Navbar
        // bg="light"
        expand="lg"
        className="nav-bar mobile-navbar pb-0"
        fixed="top"
        collapseOnSelect
        style={mpFilters ? { zIndex: "unset" } : {}}
      >
        <Container className="primary-navbar">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            ref={navbarRef}
            // aria-controls="navbar"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
            {(isLoggedIn || loginLite) && (
              <img
                src={profileIcon}
                alt="profile-icon"
                className="profile-icon"
              />
            )}
          </Navbar.Toggle>

          <Link to="/">
            <Navbar.Brand as={"div"}>
              <Image
                src={paidrezLogo}
                alt="paidrez-logo"
                className="logo"
                height={40}
              />
            </Navbar.Brand>
          </Link>

          <i
            className="search-icon"
            onClick={() => {
              setSearchPage(true);
              console.log(navbarRef.current.classList);

              if (
                // navbarRef.current.classList[1]
                navbarRef.current.classList[1] !== "collapsed"
              ) {
                navbarRef.current.click();
              }
            }}
          >
            <svg height="2em" width="1em" viewBox="0 0 24 26">
              <path
                d="m9.610352 19.2207031c-5.293946 0-9.610352-4.3164062-9.610352-9.6103515 0-5.28125004 4.316406-9.6103516 9.610352-9.6103516 5.28125 0 9.610351 4.32910156 9.610351 9.6103516 0 2.2724609-.799805 4.3544922-2.120117 6.0048828l6.436523 6.4365234c.228516.2285156.330079.5332031.330079.8378906 0 .6601563-.482422 1.2060547-1.180665 1.2060547-.317382 0-.62207-.1269531-.850585-.3427734l-6.449219-6.4746094c-1.612305 1.21875-3.618164 1.9423828-5.776367 1.9423828zm0-1.6123047c4.379882 0 7.998046-3.6054687 7.998046-7.9980468 0-4.3798828-3.618164-7.99804691-7.998046-7.99804691-4.392579 0-7.998047 3.61816411-7.998047 7.99804691 0 4.3925781 3.605468 7.9980468 7.998047 7.9980468z"
                transform="translate(0 1)"
              ></path>
            </svg>
          </i>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto mt-2">
              {(isLoggedIn || loginLite) && (
                <>
                  <Nav.Link
                    as={Link}
                    eventKey={0}
                    active={selectedAccountPage === 0}
                    onClick={() => setSelectedAccountPage(0)}
                    to="/account/reservations-and-notify"
                  >
                    My Reservations
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    eventKey={1}
                    to="/account/profile"
                    active={selectedAccountPage === 1}
                    onClick={() => setSelectedAccountPage(1)}
                  >
                    Profile
                  </Nav.Link>
                  {/* <Nav.Link
                    as={Link}
                    eventKey={2}
                    to="/account/my-hit-list"
                    active={selectedAccountPage === 2}
                    onClick={() => setSelectedAccountPage(2)}
                  >
                    My Hit List
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    eventKey={3}
                    to="/account/payment-methods"
                    active={selectedAccountPage === 3}
                    onClick={() => setSelectedAccountPage(3)}
                  >
                    Payment Methods
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    eventKey={4}
                    to="/account/communication"
                    active={selectedAccountPage === 4}
                    onClick={() => setSelectedAccountPage(4)}
                  >
                    Communication
                  </Nav.Link> */}
                  <Nav.Link
                    as={Link}
                    eventKey={5}
                    active={selectedAccountPage === 5}
                    to="/account/help-and-feedback"
                    onClick={() => setSelectedAccountPage(5)}
                  >
                    Help & Feedback
                  </Nav.Link>
                  <hr />
                </>
              )}

              <Nav.Link
                as={Link}
                to="/"
                eventKey={6}
                onClick={() => setSelectedAccountPage(-1)}
              >
                About
              </Nav.Link>
              <NavDropdown
                id="collapsible-nav-dropdown"
                // href="https://admin-web3.paidrez.com"
                // target="_blank"
                // rel="noreferrer"
                title="For Restaurants"
                eventKey={3}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/value-proposition-for-restaurants"
                  eventKey={7}
                  onClick={() => setSelectedAccountPage(-1)}
                >
                  Value Proposition
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/how-it-works"
                  eventKey={8}
                  onClick={() => setSelectedAccountPage(-1)}
                >
                  How It Works
                </NavDropdown.Item>
                <NavDropdown.Item
                  // as={Link}
                  // to="/value-proposition-for-restaurants"
                  eventKey={9}
                  onClick={() => {
                    setSelectedAccountPage(-1);
                    setTimeout(() => setModalOpen(true), 300);
                  }}
                >
                  Apply as a Partner
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                to="/marketplace"
                eventKey={10}
                onClick={() => setSelectedAccountPage(-1)}
              >
                Reservation Marketplace
              </Nav.Link>
              <hr />

              <div className="web3auth-container-mobile">
                <Web3AuthComponent
                  contractInstance={contractInstance}
                  setContractInstance={setContractInstance}
                  provider={provider}
                  setProvider={setProvider}
                  loginButtonRef={loginButtonRef}
                  collapseNavbar={() => {
                    if (navbarRef.current.classList[1] !== "collapsed") {
                      navbarRef.current.click();
                    }
                  }}
                  litelogoutButtonRef={litelogoutButtonRef}
                />
              </div>
              {/* {!isLoggedIn && (
                <LoginLite
                  litelogoutButtonRef={litelogoutButtonRef}
                  collapseNavbar={() => {
                    if (navbarRef.current.classList[1] !== "collapsed") {
                      navbarRef.current.click();
                    }
                  }}
                  handleWeb3AuthLogin={handleWeb3AuthLogin}
                />
              )} */}
            </Nav>
          </Navbar.Collapse>
        </Container>

        <Container className="secondary-navbar">
          <Row>
            <Col xs={6}>
              <CitySelector setCityId={setCityId} />
            </Col>
            <Col xs={6} className="reservation-details">
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  console.log("date selected: ", date.toISOString());
                  setStartDate(date);
                  setIsCalendarOpen(false);
                  setNoDate(false);
                }}
                open={isCalendarOpen}
                onClickOutside={() => setIsCalendarOpen(false)}
                onCalendarOpen={handleCalendarOpen}
                customInput={<DateButton />}
                minDate={subDays(new Date(), 0)}
                withPortal
              />
              <span>for</span>
              <DropdownButton
                variant="outline-secondary"
                title={
                  // eslint-disable-next-line eqeqeq
                  numberOfGuests === 0 ? "ALL" : numberOfGuests + " Guests"
                }
                className="guests-selector-button"
                onSelect={handlePersonNumberSelect}
              >
                {[0, 2, 4, 6].map((n) => (
                  <Dropdown.Item eventKey={n} key={n}>
                    {n === 0 ? "ALL" : n + " Guests"}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
        </Container>

        <AnimatePresence
          initial={false}
          exit={true}
          onExitComplete={() => null}
        >
          {modalOpen && (
            <ModalBox
              modalOpen={modalOpen}
              handleClose={() => setModalOpen(false)}
              children={<ValuePropsApplyNowPopup closeModal={closeModal} />}
              style_class="value-props-apply-now-popup"
            />
          )}
        </AnimatePresence>

        {searchPage && <Search closeSearchPage={closeSearchPage} />}
      </Navbar>
    );
  } else {
    return (
      <Navbar
        // bg="light"
        expand="md"
        className="nav-bar"
        fixed="top"
      >
        <Container>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            // aria-controls="navbar"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>

          <Link to="/">
            <Navbar.Brand as="div" className="me-2">
              <Image
                src={paidrezLogo}
                alt="paidrez-logo"
                className="logo"
                height={40}
              />
            </Navbar.Brand>
          </Link>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link> */}
              <CitySelector setCityId={setCityId} />
            </Nav>

            <Search header={false} />

            <Link to="/marketplace" className="ps-0">
              <Button className="paidrez-btn navbar-button ms-4 me-3">
                Reservation Marketplace
              </Button>
            </Link>
          </Navbar.Collapse>

          {/* <div className=""> */}
          {/* <ReactDatePicker
              selected={startDate}
              onChange={(date) => {
                console.log("date selected: ", date.toISOString());
                setStartDate(date);
                setIsCalendarOpen(false);
              }}
              open={isCalendarOpen}
              onClickOutside={() => setIsCalendarOpen(false)}
              onCalendarOpen={handleCalendarOpen}
              customInput={<DateButton />}
              minDate={subDays(new Date(), 0)}
            />
            <span>for</span>
            <DropdownButton
              variant="outline-secondary"
              title={
                // eslint-disable-next-line eqeqeq
                numberOfGuests == 0 ? "ALL" : numberOfGuests + " Guests"
              }
              onSelect={handlePersonNumberSelect}
            >
              {[0, 2, 4, 6].map((n) => (
                <Dropdown.Item eventKey={n} key={n}>
                  {n === 0 ? "ALL" : n + " Guests"}
                </Dropdown.Item>
              ))}
            </DropdownButton> */}

          <div className="">
            <Web3AuthComponent
              contractInstance={contractInstance}
              setContractInstance={setContractInstance}
              provider={provider}
              setProvider={setProvider}
              loginButtonRef={loginButtonRef}
              hideLogoutButton={true}
              logoutButtonRef={logoutButtonRef}
              litelogoutButtonRef={litelogoutButtonRef}
            />
          </div>
          {/* {!isLoggedIn && (
            <LoginLite
              litelogoutButtonRef={litelogoutButtonRef}
              handleWeb3AuthLogin={handleWeb3AuthLogin}
            />
          )} */}
          {/* </div> */}

          {(isLoggedIn || loginLite) && (
            <Navbar.Text className="login-logout-container px-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="profile-dropdown"
                >
                  <img
                    src={profileIcon}
                    alt="profile-icon"
                    className="profile-icon"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to="/account/reservations-and-notify"
                    onClick={() => setSelectedAccountPage(0)}
                  >
                    My Reservations
                  </Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    to="/account/profile"
                    onClick={() => setSelectedAccountPage(1)}
                  >
                    Profile
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    as={Link}
                    to="/account/my-hit-list"
                    onClick={() => setSelectedAccountPage(2)}
                  >
                    My Hit List
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/account/payment-methods"
                    onClick={() => setSelectedAccountPage(3)}
                  >
                    Payment Methods
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/account/communication"
                    onClick={() => setSelectedAccountPage(4)}
                  >
                    Communication
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    as={Link}
                    to="/account/help-and-feedback"
                    onClick={() => setSelectedAccountPage(5)}
                  >
                    Help & Feedback
                  </Dropdown.Item>

                  {/* <Dropdown.Divider className="my-0" /> */}

                  <Dropdown.Item onClick={() => navigate("/buy-tokens")}>
                    Buy MATIC
                  </Dropdown.Item>
                  <Dropdown.Divider className=" mb-0" />
                  <Dropdown.Item
                    className="py-2"
                    as={Button}
                    onClick={() => {
                      loginLite
                        ? litelogoutButtonRef.current?.click()
                        : logoutButtonRef.current?.click();
                    }}
                  >
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <img
                  src="profile-icon.png"
                  alt="profile-icon"
                  className="profile-icon"
                /> */}
            </Navbar.Text>
          )}

          {/* <Button
               className="login-button"
               onClick={() => {
                 handleLogin();
               }}
             >
               Login
             </Button> */}
        </Container>

        {searchPage && <Search closeSearchPage={closeSearchPage} />}
      </Navbar>
    );
  }
}

export default NavigationBar;
