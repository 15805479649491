// NOT IN USE
import { ethers } from "ethers";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { getCurrentMaticUSDPrice } from "../../Services/MaticPriceService";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import MarketplaceService from "../../Services/MarketplaceService";

const ConfirmBuyPopup = ({
  nft,
  closeModal,
  handleModalError,
  // fetchListing = () => {},
  setModalValue,
  handleBuySuccess,
  setBuying,
  fromNFTPage,
  setMoveToMyReservation = () => {},
}) => {
  const [gasFee, setGasFee] = useState();
  const [loading, setLoading] = useState(true);
  // const [buying, setBuying] = useState(false);
  const [totalFee, setTotalFee] = useState(nft.priceInMatic);
  const [maticPrice, setMaticPrice] = useState(0);
  const [copied, setCopied] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [estimationError, setEstimationError] = useState(false);
  const [gasPriceInfo, setGasPriceInfo] = useState({});

  const refreshBalance = useStoreState((state) => state.refreshBalance);
  const setRefreshBalance = useStoreActions(
    (action) => action.setRefreshBalance
  );
  const mpContractInstance = useStoreState(
    (states) => states.mpContractInstance
  );
  const balance = useStoreState((states) => states.balance);
  const account = useStoreState((states) => states.account);

  useEffect(() => {
    async function init() {
      getMaticUSDPrice();
      setInsufficientBalance(false);

      let gasPriceInfo = -1;

      for (let i = 0; i < 3; i++) {
        gasPriceInfo = await fetchGasPrice();
        console.log(gasPriceInfo);
        if (gasPriceInfo !== -1) {
          break;
        }
      }

      if (gasPriceInfo === -1) {
        alert("Can't get gas prices at the moment. Please try again later.");
        closeModal();
        return;
      }

      setGasPriceInfo(gasPriceInfo);

      const gasPrice = ethers.utils.parseUnits(
        gasPriceInfo.fast.maxFee.toFixed(6),
        "gwei"
      );

      const gasFeeForCheck = ethers.utils
        .formatUnits(ethers.BigNumber.from("500000").mul(gasPrice), "ether")
        .substring(0, 10);

      console.log(gasPriceInfo);
      console.log(gasFeeForCheck);
      console.log("amount:", Number(nft.priceInMatic) + Number(gasFeeForCheck));

      if (balance < Number(nft.priceInMatic) + Number(gasFeeForCheck)) {
        setInsufficientBalance(true);
        setLoading(false);
        console.log("Insufficient balance");
      } else {
        estimateGasFees(gasPriceInfo);
      }
    }

    init();
  }, [balance]);

  const fetchGasPrice = async () => {
    try {
      const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

      let gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("Error fetching gas price:", e);
          alert();
          closeModal();
        });

      console.log("gas price info:", gasPriceInfo);

      return gasPriceInfo;
    } catch (e) {
      console.log("ERROR::GAS_PRICE_ERROR", e);
      return -1;
    }
  };

  const getMaticUSDPrice = async () => {
    const price = await getCurrentMaticUSDPrice(1);
    setMaticPrice(price);
  };

  const estimateGasFees = async (_gasPriceInfo = gasPriceInfo) => {
    setLoading(true);
    setEstimationError(false);

    const options = {
      gasPrice: ethers.utils.parseUnits(
        _gasPriceInfo.fast.maxFee.toFixed(6),
        "gwei"
      ), // Set your desired gas price
      value: nft.price,
    };

    try {
      // Estimate the gas required for the transaction
      let gasEstimate;

      gasEstimate = await mpContractInstance.estimateGas.buyNFTItem(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        { gasLimit: 300000, value: nft.price }
      );

      console.log(gasEstimate);

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 10);

      gasFee = Number(gasFee).toFixed(5);

      setGasFee(gasFee);

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);

      const _totalFee = Number(nft.priceInMatic) + Number(gasFee);
      setTotalFee(_totalFee);

      setLoading(false);
    } catch (error) {
      console.log("Error estimating gas:", error);
      setLoading(false);
      setEstimationError(true);
    }
  };

  const handleBuy = async () => {
    // handleBuySuccess(nft);
    // return;

    // setBuying(true);
    // setTimeout(() => {
    //   setBuying(false);
    //   setModalValue(5);
    // }, 5000);

    try {
      closeModal();
      setBuying(true);

      const receipt = await mpContractInstance.buyNFTItem(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        { gasLimit: 300000, value: nft.price }
      );

      await receipt.wait();

      console.log("Buy successful");

      handleRefresh();

      // API call when transaction completes
      const res = await MarketplaceService.buyListing(nft._id);
      console.log(res);
      // fetchListing();
      setMoveToMyReservation(true);
      setBuying(false);
      if (fromNFTPage) {
        setModalValue(5);
      } else {
        handleBuySuccess(nft);
      }
    } catch (e) {
      console.log("ERROR::", e);
      setBuying(false);
      handleModalError();
    }
  };

  const handleRefresh = () => {
    setRefreshBalance(!refreshBalance);
  };

  return (
    <>
      <div className="w-100 fee-details-container">
        <div className="close-button-container" onClick={() => closeModal()}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
              fill="#D9D9D9"
            />
          </svg>
        </div>
        <h3>Confirm purchase</h3>
        <hr />
        <div>
          {loading ? (
            <div className="text-center">
              <LoadingAnimationSpoon width={150} />
            </div>
          ) : (
            <>
              <Row className="nft-info">
                <Col xs={4}>
                  <Image src={nft.s3ImageURI} className="w-100 nft-image" />
                </Col>

                <Col xs={8}>
                  <Table borderless className="nft-info-table">
                    <tbody>
                      <tr>
                        <td>
                          <p className="p-0 mb-0">Restaurant</p>
                        </td>
                        <td className="value">{nft.restoName}</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="p-0 mb-0">Date</p>
                        </td>
                        <td className="value">
                          {moment(nft.slotTime * 1000).format("MMM Do YY")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="p-0 mb-0">Time</p>
                        </td>
                        <td className="value">
                          {moment(nft.slotTime * 1000).format("LT")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="p-0 mb-0">Guests</p>
                        </td>
                        <td className="value">{nft.seatCapacity}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <hr />

              <Table borderless>
                <tbody>
                  <tr>
                    <td>
                      <b className="balance">Available Balance</b>
                    </td>
                    <td className="text-end value balance">
                      {Number(balance).toFixed(5)} MATIC
                    </td>
                  </tr>
                </tbody>
              </Table>

              {insufficientBalance && (
                <div className="insufficient-balance">
                  <div className="wallet-address-container">
                    <p>{account}</p>
                    <button
                      type="button"
                      aria-label="Copy code to clipboard"
                      title="Copy"
                      className="clean-btn"
                      onClick={() => {
                        navigator.clipboard.writeText(account);
                        setCopied(true);
                        setTimeout(() => setCopied(false), 2000);
                      }}
                      style={
                        copied ? { opacity: "1", borderColor: "#00d600" } : {}
                      }
                    >
                      <span className="copy-button-icon" aria-hidden="true">
                        <svg
                          viewBox="0 0 24 24"
                          className="copy"
                          style={copied ? { opacity: "0" } : {}}
                        >
                          <path
                            fill="currentColor"
                            d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
                          ></path>
                        </svg>
                        <svg
                          viewBox="0 0 24 24"
                          className="copy-success"
                          style={copied ? { opacity: "1" } : {}}
                        >
                          <path
                            fill="currentColor"
                            d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <p className="text-danger">
                    You don't have enough MATIC to buy this reservation.{" "}
                    <a
                      href="https://mumbaifaucet.com/"
                      className="text-danger cursor-pointer"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Click here
                    </a>{" "}
                    to buy more MATIC.
                  </p>
                  <Button className="paidrez-btn" onClick={handleRefresh}>
                    Refresh
                  </Button>{" "}
                </div>
              )}

              <hr />

              <Table className="w-100" borderless>
                <tbody>
                  <tr>
                    <td>
                      <b className="p-0">Price</b>
                    </td>
                    <td className="text-end value">{nft.priceInMatic} MATIC</td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      <b className="p-0">Gas Fee</b>
                      <small>(Estimated)</small>
                    </td>
                    <td className="text-end value">{gasFee} MATIC</td>
                  </tr>
                </tbody>
              </Table>

              {estimationError && (
                <div className="estimation-error-container">
                  <b className="text-danger">
                    We couldn't estimate the gas required for this transaction.
                    <span
                      role="button"
                      size="sm"
                      className="try-again-button"
                      onClick={() => {
                        console.log("try again");
                        estimateGasFees();
                      }}
                    >
                      Try Again
                    </span>
                  </b>
                  <br />
                  <br />
                  <small>
                    If you continue to face trouble,{" "}
                    <span
                      role="button"
                      className="contact-us"
                      onClick={() =>
                        (window.location.href =
                          "mailto:paidrezrestaurant@gmail.com")
                      }
                    >
                      {" "}
                      Contact Us
                    </span>
                  </small>
                </div>
              )}
              <hr />
              <Table borderless>
                <tbody>
                  <tr>
                    <td>
                      <b className="p-0">Total</b>
                      <small>(Mint Fee + Gas Fee)</small>
                    </td>
                    <td className="text-end value">
                      {totalFee} MATIC
                      <p className="p-0 m-0">
                        <small>
                          ≈ {(totalFee * maticPrice).toFixed(5)} USD
                        </small>
                      </p>
                    </td>
                  </tr>
                </tbody>

                {/* <tr>
              <td></td>
              <td className="value">
                <b> </b>
              </td>
            </tr> */}
              </Table>

              <div className="buttons-container">
                <Row>
                  <Col>
                    <Button onClick={closeModal} className="reject">
                      Reject
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      onClick={() => {
                        closeModal();
                        handleBuy();
                        // mintReservationNFT(nft);
                      }}
                      className="confirm"
                      disabled={insufficientBalance || estimationError}
                    >
                      {" "}
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmBuyPopup;
