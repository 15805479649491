import React from "react";
import { Button, Form, FormControl, Image } from "react-bootstrap";
import CloseIcon from "../../Icons/CloseIcon";

const SaleNFT = ({
  closeModal,
  nft,
  listingPrice,
  setListingPrice,
  setModalValue,
}) => {
  const handleButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (listingPrice && listingPrice != 0) setModalValue(8);
  };
  return (
    <div className="sale-nft">
      <CloseIcon closeModal={closeModal} />

      <Image src={nft?.s3ImageURI} alt="" className="nft-image" />

      <p className="title mb-3">
        You are listing{" "}
        {nft?.tokenName ||
          `PAIDREZ ${nft?.restoData?.name} #${nft?.tokenId
            .toString()
            .padStart(5, "0")}`}{" "}
        on Sale for
      </p>

      <Form onSubmit={handleButtonClick}>
        <div className="position-relative mb-2">
          <FormControl
            type="number"
            step={0.001}
            min={0}
            placeholder="0.0"
            className="price-input"
            value={listingPrice}
            autoFocus
            onChange={(e) => setListingPrice(e.target.value)}
          />
          <p className="matic">
            MATIC <img src="/matic-icon.svg" alt="" />
          </p>
        </div>

        <Button className="paidrez-btn w-100" type="submit">
          Continue{" "}
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="align-text-bottom"
          >
            <path
              d="M11.6935 5.57568L17.0867 10.8106M17.0867 10.8106L11.6935 16.0455M17.0867 10.8106L3.2666 10.8106"
              stroke="#FCFCF9"
              strokeWidth="2.0102"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </Form>
    </div>
  );
};

export default SaleNFT;
